import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

const getPageTitle = (pathname: string): string => {
  switch (pathname) {
    case "/":
      return "Public, Inc - パブリック株式会社";
    case "/dev":
      return "自動化NOW";
    case "/privacy":
      return "プライバシーポリシー";
    default:
      return "Public, Inc - パブリック株式会社";
  }
};

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    const pageTitle = getPageTitle(location.pathname);
    document.title = pageTitle;

    logEvent(analytics, "page_view", {
      page_path: location.pathname,
      page_title: pageTitle,
    });
  }, [location]);
};
