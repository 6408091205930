import React from 'react';
import './App.css';
import theme from "theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import Router from "components/Router";
import { CssBaseline } from "@mui/material";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useAnalytics } from './hooks/useAnalytics';
import { HelmetProvider } from 'react-helmet-async';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzh-E4G9EDlKRz8l_HtALsoFyRuh7FeV8",
  authDomain: "public-inc.firebaseapp.com",
  projectId: "public-inc",
  storageBucket: "public-inc.firebasestorage.app",
  messagingSenderId: "539172955129",
  appId: "1:539172955129:web:69cfb45e05e93a20135328",
  measurementId: "G-RKS1MC2W4N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Create a new component that uses the analytics hook
const AppContent: React.FC = () => {
  useAnalytics();
  return <Router />;
};

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppContent />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
