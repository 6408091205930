import React, { FC } from "react";
import { Switch, Route } from "react-router-dom"

import Home from "pages/Home";
import Dev from "pages/Dev";
import Privacy from "pages/Privacy";
type Props = {}
const Router: FC<Props> = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/dev" component={Dev} />
      <Route exact path="/privacy" component={Privacy} />
    </Switch>
  )
}

export default Router;