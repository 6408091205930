import React, { FC } from "react";
import { Container, styled, Box, Typography, Link } from "@mui/material";
import { Helmet } from 'react-helmet-async';

const FullScreenContainer = styled(Box)({
  minHeight: "100vh",
  width: "100vw",
  position: "relative",
  overflow: "auto",
  backgroundColor: "#fff",
  display: "flex",
  fontFamily: "'Noto Sans JP', sans-serif",
});

const LeftSection = styled(Box)({
  // Remove padding from here
});

const RightSection = styled(Box)({
  padding: "20px",
});

const LeftImage = styled(Box)({
  backgroundImage: "url('/people.jpg')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
});

const Logo = styled('img')({
  width: "250px",
  height: "auto",
});

const LeftText = styled(Typography)({
  borderRadius: "8px",
  padding: "5px 8px",
  backgroundColor: "#000",
  color: "#fff",
  fontSize: "1.1rem",
  fontWeight: 700,
  fontFamily: "'Noto Sans JP', sans-serif",
});

const PublicText = styled(Typography)({
  borderRadius: "8px",
  padding: "0px 10px",
  backgroundColor: "#000",
  position: "absolute",
  bottom: "65px",
  left: "20px",
  color: "#fff",
  fontSize: "2.8rem",
  fontWeight: 700,
  fontFamily: "'GT America Trial', sans-serif",
});

const RightContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const NavTitles = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
});

const NavTitle = styled(Typography)({
  fontSize: "2.8rem",
  fontWeight: 700,
  fontFamily: "'GT America Trial', sans-serif",
});

const CompanyInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const InfoRow = styled(Box)<{ noborder?: boolean }>(({ noborder }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  paddingBottom: "16px",
  borderBottom: noborder ? "none" : "1px solid #dee2e6",
}));

const InfoTitle = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 700,
  minWidth: "100px",
  fontFamily: "'Noto Sans JP', sans-serif",
});

const InfoContent = styled(Typography)({
  fontSize: "1rem",
  fontFamily: "'Noto Sans JP', sans-serif",
  fontWeight: 700,
});

const ContactMessage = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 700,
  fontFamily: "'Noto Sans JP', sans-serif",
});

const ContactLink = styled(Link)({
  color: "#0066cc",
  textDecoration: "underline",
  fontWeight: 700,
  "&:hover": {
    opacity: 0.7,
  },
});

const Home: FC = () => {
  return (
    <>
      <Helmet>
        <title>Public, Inc - パブリック株式会社</title>
      </Helmet>
      <FullScreenContainer sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <LeftSection sx={{
          width: { xs: "100%", md: "50%" },
          minHeight: { xs: "100vh", md: "100vh" },
          padding: { xs: "0", md: "20px" }
        }}>
          <LeftImage sx={{
            width: "100%",
            height: { xs: "100vh", md: "calc(100vh - 40px)" },
            borderRadius: { xs: "0", md: "8px" }
          }}>
            <Logo src="/public-circle.svg" alt="PUBLIC" />
            <LeftText>あらたな価値を創り、社会に貢献する</LeftText>
          </LeftImage>
        </LeftSection>
        <RightSection sx={{
          width: { xs: "100%", md: "50%" },
          minHeight: { xs: "50vh", md: "100vh" },
          padding: "20px"
        }}>
          <RightContent sx={{
            width: "100%",
            minHeight: { xs: "50vh", md: "calc(100vh - 40px)" },
            borderRadius: { xs: "0", md: "8px" }
          }}>
            <NavTitles sx={{ paddingBottom: { xs: "60px", md: "0" } }}>
              <NavTitle>COMPANY</NavTitle>
              <CompanyInfo>
                <InfoRow>
                  <InfoTitle>会社名</InfoTitle>
                  <InfoContent>パブリック株式会社</InfoContent>
                </InfoRow>
                {/* <InfoRow>
                  <InfoTitle>設立</InfoTitle>
                  <InfoContent>2021年2月</InfoContent>
                </InfoRow> */}
                <InfoRow>
                  <InfoTitle>代表取締役</InfoTitle>
                  <InfoContent>松本拓也</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>住所</InfoTitle>
                  <InfoContent>東京都渋谷区桜丘14-5 渋谷サニーヒル405</InfoContent>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>事業内容</InfoTitle>
                  <InfoContent>インターネットビジネスの企画・開発・運営</InfoContent>
                </InfoRow>
              </CompanyInfo>
              <NavTitle>SERVICES</NavTitle>
              <InfoRow noborder>
                <InfoContent>IN DEVELOPMENT🧑‍💻</InfoContent>
              </InfoRow>
              <NavTitle>CONTACT</NavTitle>
              <ContactMessage>
                お問い合わせは<ContactLink href="https://forms.gle/wrjBFLmGLQQ9GcBP9" target="_blank" rel="noopener noreferrer">こちら</ContactLink>までお願いします。通常2-3営業日でご返信いたします。
              </ContactMessage>
            </NavTitles>
          </RightContent>
        </RightSection>
      </FullScreenContainer>
    </>
  );
};

export default Home;