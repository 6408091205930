import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { Helmet } from 'react-helmet-async';
import { getAnalytics, logEvent } from 'firebase/analytics';

const Dev: React.FC = () => {
  const analytics = getAnalytics();

  const handleHeaderCtaClick = () => {
    logEvent(analytics, 'header_cta_click', {
      button_name: '今すぐ相談する',
      button_location: 'header'
    });
  };

  const handleFooterCtaClick = () => {
    logEvent(analytics, 'footer_cta_click', {
      button_name: '今すぐ自動化する',
      button_location: 'footer'
    });
  };

  return (
    <>
      <Helmet>
        <title>自動化NOW</title>
      </Helmet>
      <Box sx={{ width: "100%" }}>
        {/* Header */}
        <Box sx={{
          width: "100%",
          py: 2,
          borderBottom: "1px solid #e0e0e0",
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 1000,
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Box style={{ backgroundColor: "#000", padding: "5px 10px", borderRadius: "0px" }}>
                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 900,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    letterSpacing: "-0.07em",
                    color: "#fff",
                  }}
                >
                  自動化NOW
                </Typography>
              </Box>
              <Button
                component="a"
                href="https://forms.gle/6q9TMJAeMLm4gsJb7"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleHeaderCtaClick}
                sx={{
                  backgroundColor: "#0066FF",
                  color: "#fff",
                  py: 1.5,
                  px: 2,
                  fontSize: "1rem",
                  fontWeight: 700,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  borderRadius: "50px",
                  letterSpacing: "-0.09em",
                  "&:hover": {
                    backgroundColor: "#0066FF",
                  },
                }}
              >
                今すぐ相談する
              </Button>
            </Box>
          </Container>
        </Box>

        {/* Main Title Section */}
        <Box sx={{
          width: "100%",
          py: { xs: 2, md: 4 },
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Box sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "center" },
              gap: { xs: 2, md: 0 },
            }}>
              <Typography
                sx={{
                  fontSize: { xs: "2.5rem", md: "4rem" },
                  fontWeight: 800,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  letterSpacing: "-0.05em",
                  color: "#000",
                  lineHeight: 1.2,
                }}
              >
                単純作業の<br />自動化いろいろ
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "2rem", md: "2.8rem" },
                  fontWeight: 800,
                  letterSpacing: "-0.05em",
                  color: "#000",
                  fontFamily: "'Noto Sans JP', sans-serif"
                }}
              >
                39,800円から。
              </Typography>
            </Box>
          </Container>
        </Box>

        {/* Empty Box for Image */}
        <Box sx={{
          width: "100%",
          py: 1,
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Box
              component="img"
              src="/people.png"
              alt="People working together"
              sx={{
                width: "100%",
                height: { xs: "300px", md: "500px" },
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Container>
        </Box>

        {/* Automation Examples Title Section */}
        <Box sx={{
          width: "100%",
          py: { xs: 4, md: 8 },
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Typography
              sx={{
                fontSize: { xs: "1.8rem", md: "3rem" },
                fontWeight: 800,
                fontFamily: "'Noto Sans JP', sans-serif",
                letterSpacing: "-0.05em",
                color: "#000",
                textAlign: "left",
                mb: 2,
              }}
            >
              こんな作業を自動化できます
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "0.9rem", md: "1.1rem" },
                fontWeight: 400,
                fontFamily: "'Noto Sans JP', sans-serif",
                letterSpacing: "-0.05em",
                color: "#666",
                textAlign: "left",
                lineHeight: 1.8,
                mb: { xs: 4, md: 6 },
              }}
            >
              面倒な単純作業を全て自動化。経験豊富な開発者があなたの会社のためだけにカスタマイズして納品します。
            </Typography>

            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/sales.png"
                    alt="Sales list creation"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    営業リストの作成
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      letterSpacing: "-0.05em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                    }}
                  >
                    インターネット上の情報を定期的にスクレイピングして、営業リストを作成 & 更新。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/instagram.png"
                    alt="Instagram automation"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    インスタグラムの運用
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    コメントへの返信やDMの対応など、手動で行っている作業を完全自動化。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/spreadsheet.png"
                    alt="Sales and inventory data output"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    売上や在庫データの出力
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    様々なデータをスプレッドシートに自動で出力。分析やマーケティングに利用。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/ecommerce.png"
                    alt="EC listing automation"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    ECの出品作業
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    現状のフローを改善。出品時に繰り返す行う処理をシステムで自動化。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/communication.png"
                    alt="Email and chat automation"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    メールやチャットツール上の自動化
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    特定の単語を含む投稿をチェックして、アクションを実行。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: { xs: 2, md: 4 } }}>
                  <Box
                    component="img"
                    src="/importing.png"
                    alt="Data input automation"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", md: "250px" },
                      objectFit: "cover",
                      borderRadius: "8px",
                      mb: 2,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "1.1rem", md: "1.3rem" },
                      fontWeight: 700,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    データ入力作業
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.85rem", md: "0.9rem" },
                      color: "#666",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.8,
                      fontWeight: 500,
                      letterSpacing: "-0.05em",
                    }}
                  >
                    繰り返し発生する大量のコピー&ペースト処理を自動化。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Why Choose Us Section */}
        <Box sx={{
          width: "100%",
          py: { xs: 4, md: 8 },
          bgcolor: "#fafafc",
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Typography
              sx={{
                fontSize: { xs: "1.8rem", md: "3rem" },
                fontWeight: 800,
                fontFamily: "'Noto Sans JP', sans-serif",
                letterSpacing: "-0.05em",
                color: "#000",
                textAlign: "left",
                mb: { xs: 4, md: 6 },
              }}
            >
              自動化NOWが選ばれる理由
            </Typography>

            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={4}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 5 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.7rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: { xs: 2, md: 3 },
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      color: "#0066FF"
                    }}
                  >
                    リーズナブルな<br />料金設定
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    事前に料金の確定を行うので、それ以上のご請求はありません。自動化の規模や難易度によって、料金が変わります。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 4 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.7rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: { xs: 2, md: 3 },
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      color: "#0066FF"
                    }}
                  >
                    とにかく自由に<br />
                    カスタマイズ
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    実際に弊社に所属する開発者が開発を行います。既存のサービスでは対応しきれない要望に対応可能です。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 4 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.7rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: { xs: 2, md: 3 },
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      color: "#0066FF"
                    }}
                  >
                    開発者が直接<br />お問い合わせの対応
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    実際に開発を行う開発者がお問い合わせの対応を行います。経験豊富な開発者がご質問にお応えします。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Pricing Section */}
        <Box sx={{
          width: "100%",
          py: { xs: 4, md: 8 },
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Box style={{ backgroundColor: "#FDF36E", padding: "10px 15px", borderRadius: "0px", display: "inline-block" }} sx={{ mb: { xs: 4, md: 6 } }}>
              <Typography
                sx={{
                  fontSize: { xs: "1.8rem", md: "3rem" },
                  fontWeight: 800,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  letterSpacing: "-0.05em",
                  color: "#000",
                  textAlign: "left",
                }}
              >
                ご依頼しやすい料金で
              </Typography>
            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: { xs: 2, md: 3 },
              mb: 4
            }}>
              <Box sx={{
                border: "1px solid #000",
                borderRadius: "50px",
                p: { xs: 3, md: 4 },
                height: { xs: "auto", md: "500px" },
                width: { xs: "100%", md: "30%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Typography
                  sx={{
                    fontSize: { xs: "2rem", md: "3rem" },
                    fontWeight: 800,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 1,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  開発費
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1.8rem", md: "2.5rem" },
                    fontWeight: 900,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 4,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  39,800円から
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    fontWeight: 500,
                    color: "#000",
                    letterSpacing: "-0.06em",
                    fontFamily: "'Noto Sans JP', sans-serif",
                    lineHeight: 1.7,
                  }}
                >
                  自動化の規模や難易度によって開発費が異なります。
                  まずはフォームよりお気軽にご相談くださいませ。
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "2rem", md: "3rem" },
                  fontWeight: 800,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  color: "#000",
                  display: { xs: "none", md: "block" },
                }}
              >
                +
              </Typography>
              <Box sx={{
                border: "1px solid #000",
                borderRadius: "50px",
                p: { xs: 3, md: 4 },
                height: { xs: "auto", md: "500px" },
                width: { xs: "100%", md: "30%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Typography
                  sx={{
                    fontSize: { xs: "2rem", md: "3rem" },
                    fontWeight: 800,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 1,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  サーバー費
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1.8rem", md: "2.5rem" },
                    fontWeight: 900,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 4,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  980円/月から
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    fontWeight: 500,
                    color: "#000",
                    letterSpacing: "-0.06em",
                    fontFamily: "'Noto Sans JP', sans-serif",
                    lineHeight: 1.7,
                  }}
                >
                  定期的に実行したい自動化処理の場合、システムを動かすためのサーバー費が毎月発生します。金額はシステム規模によって異なります。※ 単発の自動化の場合、サーバー費は発生しません。
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "2rem", md: "3rem" },
                  fontWeight: 800,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  color: "#000",
                  display: { xs: "none", md: "block" },
                }}
              >
                +
              </Typography>
              <Box sx={{
                border: "1px solid #000",
                borderRadius: "50px",
                p: { xs: 3, md: 4 },
                height: { xs: "auto", md: "500px" },
                width: { xs: "100%", md: "30%" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Typography
                  sx={{
                    fontSize: { xs: "2rem", md: "3rem" },
                    fontWeight: 800,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 1,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  保守運用費
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1.8rem", md: "2.5rem" },
                    fontWeight: 900,
                    fontFamily: "'Noto Sans JP', sans-serif",
                    mb: 4,
                    textAlign: "left",
                    letterSpacing: "-0.05em",
                  }}
                >
                  都度見積もり
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "0.9rem", md: "1rem" },
                    fontWeight: 500,
                    color: "#000",
                    letterSpacing: "-0.06em",
                    fontFamily: "'Noto Sans JP', sans-serif",
                    lineHeight: 1.7,
                  }}
                >
                  納品から1ヶ月以内の不具合に関しては無料で修正を承ります。その後の保守運用は都度お見積もりを行います。
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: "1rem", fontWeight: 500, color: "#000", letterSpacing: "-0.06em", fontFamily: "'Noto Sans JP', sans-serif", lineHeight: 1.7 }}>※ 全て税別表記になります</Typography>
          </Container>
        </Box>

        {/* Process Section */}
        <Box sx={{
          width: "100%",
          py: { xs: 4, md: 8 },
          bgcolor: "#fafafc",
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Typography
              sx={{
                fontSize: { xs: "1.8rem", md: "3rem" },
                fontWeight: 800,
                fontFamily: "'Noto Sans JP', sans-serif",
                letterSpacing: "-0.05em",
                color: "#000",
                textAlign: "left",
                mb: { xs: 4, md: 6 },
              }}
            >
              自動化までの流れをご紹介します
            </Typography>

            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 4 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.8rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box sx={{
                      fontSize: { xs: "2rem", md: "2.5rem" },
                      fontWeight: 900,
                    }}>
                      1
                    </Box>
                    まずはお問い合わせ
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1.1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    自動化をご希望の概要をお問い合わせでお伝えください。概要から簡単なお見積もりを実施させていただきます。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 4 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.8rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box sx={{
                      fontSize: { xs: "2rem", md: "2.5rem" },
                      fontWeight: 900,
                    }}>
                      2
                    </Box>
                    オンラインにてヒヤリング
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1.1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    より詳細な自動化の内容をオンラインの画面共有を用いて実施します。ヒヤリング後、要件の定義を実施。最終的な金額と納品までの日数をお伝えさせていただきます。最短1週間ほどで納品が完了します。
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{
                  bgcolor: "#fff",
                  borderRadius: "16px",
                  p: { xs: 3, md: 4 },
                  height: "100%",
                }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.4rem", md: "1.8rem" },
                      fontWeight: 800,
                      fontFamily: "'Noto Sans JP', sans-serif",
                      mb: 1,
                      letterSpacing: "-0.05em",
                      lineHeight: 1.4,
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box sx={{
                      fontSize: { xs: "2rem", md: "2.5rem" },
                      fontWeight: 900,
                    }}>
                      3
                    </Box>
                    あとは、納品を待つだけ！
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1.1rem" },
                      fontWeight: 500,
                      color: "#000",
                      letterSpacing: "-0.06em",
                      fontFamily: "'Noto Sans JP', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    契約完了 + お支払い後、開発に入らせていただきます。納品が完了しましたらオンラインでご一緒に動作確認を実施いたします。納品から1ヶ月以内の不具合に関しては無料で修正ご対応させていただきます。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Call to Action Section */}
        <Box sx={{
          width: "100%",
          bgcolor: "#FDF36E",
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px", p: { xs: 6, md: 20 } }}>
            <Typography
              sx={{
                fontSize: { xs: "2rem", md: "3.5rem" },
                fontWeight: 800,
                fontFamily: "'Noto Sans JP', sans-serif",
                letterSpacing: "-0.05em",
                color: "#000",
                textAlign: "center",
                lineHeight: 1.4,
                mb: { xs: 3, md: 4 },
                whiteSpace: { xs: "normal", md: "nowrap" },
              }}
            >
              「この作業、自動化できませんか？」<br />とお気軽にご相談ください。
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                component="a"
                href="https://forms.gle/6q9TMJAeMLm4gsJb7"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleFooterCtaClick}
                sx={{
                  backgroundColor: "#0066FF",
                  color: "#fff",
                  py: { xs: 1.5, md: 2 },
                  px: { xs: 4, md: 6 },
                  minWidth: { xs: "100%", md: "400px" },
                  fontSize: { xs: "1.1rem", md: "1.2rem" },
                  fontWeight: 700,
                  fontFamily: "'Noto Sans JP', sans-serif",
                  borderRadius: "50px",
                  letterSpacing: "-0.09em",
                  "&:hover": {
                    backgroundColor: "#0052CC",
                  },
                }}
              >
                今すぐ自動化する
              </Button>
            </Box>
          </Container>
        </Box>

        {/* Footer */}
        <Box sx={{
          width: "100%",
          py: { xs: 3, md: 4 },
          borderTop: "1px solid #e0e0e0",
        }}>
          <Container maxWidth="lg" sx={{ maxWidth: "1024px" }}>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              gap: { xs: 2, md: 4 },
              flexWrap: "wrap",
            }}>
              <Typography
                component="a"
                href="https://public.st"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontSize: { xs: "0.8rem", md: "0.9rem" },
                  color: "#666",
                  fontFamily: "'Noto Sans JP', sans-serif",
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#000",
                  },
                }}
              >
                会社概要
              </Typography>
              <Typography
                component="a"
                href="/privacy"
                sx={{
                  fontSize: { xs: "0.8rem", md: "0.9rem" },
                  color: "#666",
                  fontFamily: "'Noto Sans JP', sans-serif",
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#000",
                  },
                }}
              >
                プライバシーポリシー
              </Typography>
              <Typography
                component="a"
                href="https://forms.gle/6q9TMJAeMLm4gsJb7"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontSize: { xs: "0.8rem", md: "0.9rem" },
                  color: "#666",
                  fontFamily: "'Noto Sans JP', sans-serif",
                  cursor: "pointer",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#000",
                  },
                }}
              >
                お問い合わせ
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Dev;
